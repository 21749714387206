import React, { useState } from "react";
import { graphql } from "gatsby";
import Layout from "../components/layout";
import GenericPage from "../components/generic-page";
import EventCardsMedium from "../components/event-cards-medium";
import SEO from "../components/seo";
import { useMicroCopy } from "../hooks/microcopy";
import { copy } from "../components/copy-handling";
import { loadNextPage } from "../helpers/general";
import { collections } from "../constants";

import { injectIntl } from "gatsby-plugin-react-intl";

const ArticleTemplate = ({ data, intl, pageContext: { properties = {}, localizedSlugs = {} } }) => {
  const copies = useMicroCopy();
  const {
    seoKeywords,
    seoDescription,
    articleName,
    noIndex,
    categories,
  } = data?.contentfulArtikkeli;

  const isSeparateCategory = categories?.some((category) => category.isSeparateCategory);
  const articles = isSeparateCategory ? data?.articlesInCategory?.edges : data?.articles?.edges;
  const locale = intl?.locale;

  const [nextPageId, setNextPageId] = useState(1);
  const [articlePosts, setArticlePosts] = useState(articles);
  const collectionId = isSeparateCategory
    ? `${collections.articlesByCategory}/${categories?.map((category) => category.categoryName)[0]}`
    : "articles-template";

  const handleLoadMore = async () => {
    const result = await loadNextPage(nextPageId, collectionId, locale);
    const filteredPosts = result.items.filter(
      (post) => !articlePosts.some((articlePost) => articlePost.node.id === post.node.id),
    );
    setArticlePosts([...articlePosts, ...filteredPosts]);
    const nextPage = result.isLastPage ? null : result.nextPageId;
    setNextPageId(nextPage);
  };

  const showLoadMore = nextPageId !== null;

  return (
    <Layout data={data} properties={properties} localizedSlugs={localizedSlugs}>
      <SEO
        keywords={seoKeywords}
        title={articleName}
        description={seoDescription?.seoDescription}
        noIndex={noIndex}
      />
      <section className="flex flex-wrap content-section">
        <GenericPage data={data?.contentfulArtikkeli} />
      </section>
      <section className="xl:max-w-layout-xl xxl:max-w-layout-xxl mx-auto content-section">
        <h2 className="c-h2 spacing-break"> {copy(copies, "article.MoreNews", intl?.locale)}</h2>
        <div className="mb-40">
          <EventCardsMedium
            eventPosts={articlePosts}
            showImages={false}
            showCategories={!isSeparateCategory}
          />
          {showLoadMore && (
            <div className="flex w-full justify-center mt-16">
              <button className="btn loadMore" onClick={() => handleLoadMore()}>
                {copy(copies, "button.loadMore", intl?.locale)}
              </button>
            </div>
          )}
        </div>
      </section>
    </Layout>
  );
};

export default injectIntl(ArticleTemplate);

export const pageQuery = graphql`
  query articlePageQuery($slug: String!, $locale: String, $categoryNames: [String]!) {
    contentfulArtikkeli(slug: { eq: $slug }, node_locale: { eq: $locale }) {
      __typename
      articleName
      categories {
        categoryName
        isSeparateCategory
      }
      carousel {
        carouselImages {
          ... on Node {
            ... on ContentfulAsset {
              id
              description
              gatsbyImageData(layout: CONSTRAINED, width: 1200, aspectRatio: 1.77777778)
            }
          }
        }
        wide
      }
      description {
        raw
        references {
          ...RichTextReferencesFragment
        }
      }
      ingress {
        ingress
      }
      headImage {
        description
        gatsbyImageData(layout: CONSTRAINED, width: 1500, aspectRatio: 1.77777778)
      }
      headImageMobile: headImage {
        description
        gatsbyImageData(layout: CONSTRAINED, width: 800, aspectRatio: 0.8)
      }
      publishDate(formatString: "L", locale: "fi")
      slug
      noIndex
    }
    ...AllArticlesListOnTemplateFragment
    ...ArticlesInCategoryListOnTemplateFragment
    ...AllFragment
  }
`;
